<template>
  <div class="statistik-blank text-center" style="margin-top: 10px">
    <h2 class="text-dark">
      <b> Data Tryout Tidak Ditemukan </b>
    </h2>
    <span>Mulai berlangganan paket tryout sekarang</span> <br />
    <b-button class="mt-2" variant="danger" :to="{ name: 'paket_tryout' }"> Pilih Paket Sekarang </b-button>
  </div>
</template>

<script>
import { BButton, BLink } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },
};
</script>

<style></style>
