var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {}, [_c('b-tabs', {
    attrs: {
      "content-class": "pt-1"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Tersedia"
    }
  }, [_c('Tersedia')], 1), _c('b-tab', {
    attrs: {
      "title": "Tryout Saya"
    }
  }, [_c('TryoutSaya')], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }