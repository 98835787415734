var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statistik-blank text-center",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._m(0), _c('span', [_vm._v("Mulai berlangganan paket tryout sekarang")]), _vm._v(" "), _c('br'), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "danger",
      "to": {
        name: 'paket_tryout'
      }
    }
  }, [_vm._v(" Pilih Paket Sekarang ")])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "text-dark"
  }, [_c('b', [_vm._v(" Data Tryout Tidak Ditemukan ")])]);
}]

export { render, staticRenderFns }